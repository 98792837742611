<template>
	<div>
		<div v-if="without_horse" class="row">
			<div class="col-auto ml-auto">
	    		<b-button variant="primary" @click="onSelectHorses([])">
	    			{{ $t('global.continue_without_horse') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']"/>
	            </b-button>
			</div>
    	</div>
	    <CustomTable
	        id_table="choix_horse"
	        primaryKey="horse_id"
			:transformer="['HorseTransformer', 'withResidLieu']"
	        :preselected_items="preselected_horses"
	        :selected_ids.sync="selected_ids"
	        :hrefsRoutes="config_table_hrefs"
			:limit="limitHorses"
			:selectMode="unique ? 'single' : 'multi'"
			:base-filters="filters"
	    />
	</div>
</template>

<script type="text/javascript">
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'
    import Horse from '@/mixins/Horse.js'
    import Navigation from '@/mixins/Navigation.js'
    import Shutter from '@/mixins/Shutter.js'

	export default {
		name: "horseSelectionV2",
        mixins:  [Horse, Shutter, Navigation],
        props: {
            selected_horses_ids: { type: Array, default: () => [] },
			unique: { type: Boolean, default: false },
			code_onboarding: { type: String, default: '' },
			without_horse: { type: Boolean, default: false }
        },
		data () {
			return {
                horses : [],
				preselected_horses: [], /* Liste de chevaux préselectionnés */
				limitHorses: 0,
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                },
				events_tab: {
					'TableAction::goToSelectHorses': this.onSelectHorses
				}
			}
		},
		created() {
			this.limitHorses = this.getUserHorseLimit()
		},
		mounted() {
			this.init_component() // Initialiser la vue (visuel)
		},
		methods: {
            init_component() {
                this.preselected_horses = [...this.selected_horses_ids]
            },
			async onSelectHorses(horse_ids) {
				const cmp = {
					act_create: 'components/WriteActe',
					mvt_create: 'components/WriteMouvement',
					tiers_manage: 'components/Horse/Tiers'
				}

				if(this.code_onboarding) {
					const name = this.code_onboarding
					let props = {
						code_onboarding: this.code_onboarding,
						horses_ids: horse_ids
					}

					if(this.code_onboarding == 'tiers_manage') {
						props.horse = await this.getHorseById(horse_ids[0])
					}

					this.shutterPanel().open({
						name: name,
						title: this.code_onboarding,
						cmpPath: cmp[this.code_onboarding],
						props,
						onOk: this.closeShutters
					})
				}
				else {
					this.ok(horse_ids)
				}
			},
			closeShutters() {
				const params =  {
					code: this.code_onboarding,
					done: 1,
					skip: 0
				}

				this.ok(params)
				this.shutterPanel().close(this.code_onboarding + '_select')
				this.shutterPanel().close(this.code_onboarding)
			}
		},
		computed: {
			selected_ids: {
				get() {
					return this.selected_horses_ids
				},
				set(val) {
					this.$emit('update:selected_horses_ids', val)

					if(this.unique && val.length > 0) {
						this.onSelectHorses(val)
					}
				}
			},
			filters() {
				let filters = {
					tiers: {
						column: 'horse_inactive',
						operator: 'isEqualTo',
						value: 0
					}
				}

				return filters
			}
		},
        components: {
			CustomTable,
			ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
		}
	}
</script>
